
import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Text, Linking } from 'react-native';
import configData from "../properties/config.json";
import "../css/Reporting.css"

const cofees_env=process.env.REACT_APP_ENVIRONMENT;

class MainContentReporting extends Component {

  constructor(props) {
    super(props)

    this.state = {
      number: 0,
      toggle: false
    }
  }

  changeBackground(e) {
    //e.target.style.background = '#5987da';
    e.target.style.background = '#4682b4';
    e.target.style.color = 'white'
  }

  originalBackground(e) {
    e.target.style.background = '#dce3ec';
    e.target.style.color = 'black'
  }

  render() {
    return (
      <>
        {
          <Container>
            <Row>&nbsp;</Row>
            <ul>

          
              <Row>
                <li><a href={configData[cofees_env].USER_FEEDBACK} target="_blank" onMouseOver={this.changeBackground} onMouseOut={this.originalBackground}>Client Feedback Analytics</a></li>            
              </Row>
              <Row>&nbsp;</Row>
              <Row>&nbsp;</Row>
              <Row>
               
                  <ul>
          
                  <li><a href={configData[cofees_env].NPS.SUMMARY} target="_blank" onMouseOver={this.changeBackground} onMouseOut={this.originalBackground}>NPS Summary</a></li>            
           
                  <li>
                  <a href={configData[cofees_env].NPS.DEEP_DIVE} target="_blank" onMouseOver={this.changeBackground} onMouseOut={this.originalBackground}>NPS Deep Dive</a>

                  </li>
                  </ul>

              </Row>
              <Row>&nbsp;</Row>
              <Row>&nbsp;</Row>
              <Row>
  
                  <ul>
                  <li>
                  <a href={configData[cofees_env].CSAT.SUMMARY} target="_blank" onMouseOver={this.changeBackground} onMouseOut={this.originalBackground}>CSAT Summary</a>
                  </li>
                  <li>
                  <a href={configData[cofees_env].CSAT.DEEP_DIVE} target="_blank" onMouseOver={this.changeBackground} onMouseOut={this.originalBackground}>CSAT Deep Dive</a>
                  </li>
                  </ul>
     
              </Row>
            </ul>
          </Container>
        }

      </>
    );
  }
}

export default MainContentReporting;