import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginPage from "./LoginPage";
import { TopBar } from './TopBar'
import { SideBar } from './SideBar'
import MainContentHome from './MainContentHome'
import MainContentRulesConfigure from './MainContentRulesConfigure'
import MainContentViewRules from './MainContentViewRules'
import MainContentReporting from './MainContentReporting'
import { useBetween } from "use-between";
import { useFormState } from './SideBar'
import { useLoginState } from './LoginPage'
import { Redirect } from 'react-router-dom'
import { authProvider } from "./authProvider";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { Link, Text } from '@fluentui/react';



export default function AppRouter2(param) {

  const useSharedFormState = () => useBetween(useFormState);
  const { currentTab, currentTabWidth } = useSharedFormState();


  const useSharedLoginState = () => useBetween(useLoginState);
  const { loggedIn, setLoggedIn } = useSharedLoginState();
  return (
    <Router>
      <Switch>

        {/* <Route exact path="/">
          <Container fluid>
            <Row style={{ height: '100vh', backgroundColor: 'grey' }}>
              <Col className='border d-flex align-items-center justify-content-center' style={{ backgroundColor: '#e3e9f8' }} >
                <LoginPage />
              </Col>
            </Row>
          </Container>
        </Route>  */}

        <Route exact path="/">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <MainContentHome />
              </Col>
            </Row>
          </Container>
        </Route>


        {/* To avoid margins on left and right side INSIDE the column, add padding on either side */}

        {/* {loggedIn === 'yes' ? */}

        {/* <Route exact path="/home"> */}

        <Route exact path="/home">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <MainContentHome />
              </Col>
            </Row>
          </Container>
        </Route>

        {/* : <Redirect to='/' />
        } */}


        <Route exact path="/configurerules">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ maxWidth: currentTabWidth, paddingLeft: 0, paddingRight: 0 }}>
                <SideBar />
              </Col>
              <Col>
                <MainContentRulesConfigure email={param.email} />
              </Col>
            </Row>
          </Container>
        </Route>



        <Route exact path="/viewrules">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <MainContentViewRules />
              </Col>
            </Row>
          </Container>
        </Route>



        <Route exact path="/reporting">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <MainContentReporting />
              </Col>
            </Row>
          </Container>
        </Route>

        <Route exact path="/AllSubscription">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <iframe title="Azure ESG Cost Report" width="100%" height="100%" src="https://app.powerbi.com/reportEmbed?reportId=1af927f8-2d15-4ec9-9d00-7f2f6892985d&appId=dc4dac2d-5c83-4c63-863a-0baa4e4aef9d&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
            </Row>
          </Container>
        </Route>

        <Route exact path="/orphan">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <iframe title="Azure ESG Cost Report" width="100%" height="100%" href="https://portal.azure.com/#blade/AppInsightsExtension/UsageNotebookBlade/ComponentId/%2Fsubscriptions%2Ff19a4989-b920-4ecb-91b7-fdeb5090c024%2FresourceGroups%2Frgrp-uva2-mt-sharedAppinsgtUAT/ConfigurationId/%2Fsubscriptions%2Ff19a4989-b920-4ecb-91b7-fdeb5090c024%2Fresourcegroups%2Frgrp-uva2-mt-sharedappinsgtuat%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2F58a1df59-f1c0-4a2c-aad9-67b47a0d7014/Type/workbook/WorkbookTemplateName/Index%20Azure%20Orphan%20Resources" frameborder="0" allowFullScreen="true"></iframe></Col>
            </Row>
          </Container>
        </Route>

        <Route exact path="/nonprodcost">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <iframe title="Azure ESG Cost Report" width="100%" height="100%" target="_blank" src="https://app.powerbi.com/reportEmbed?reportId=ca053089-10e7-4063-a0a3-220492851434&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
            </Row>
          </Container>
        </Route>

        <Route exact path="/ProdSubscription">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <iframe title="Azure ESG Cost Report" width="100%" height="100%" src="https://app.powerbi.com/reportEmbed?reportId=a91dc616-de65-4133-8da4-c2f985f9c03b&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d" frameborder="0" allowFullScreen="true"></iframe></Col>
            </Row>
          </Container>
        </Route>

        <Route exact path="/IndexV2_Prod">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <iframe title="Azure ESG Cost Report" width="100%" height="100%" src="https://app.powerbi.com/reportEmbed?reportId=4672b2b7-a1de-402f-a3d9-bed2908d49a2&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
            </Row>
          </Container>
        </Route>

        <Route exact path="/IndexV2_Non-Prod">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <iframe title="Azure ESG Cost Report" width="100%" height="100%" src="https://app.powerbi.com/reportEmbed?reportId=61cca130-d4e7-406a-8b8e-0c2fb67f4c43&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
            </Row>
          </Container>
        </Route>


        <Route exact path="/opencost">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 60, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col>
             
              <img src="/wip.png" alt="logo" style={{ alignSelf: 'center' }}/> 
              
              </Col>
            </Row>
          </Container>
        </Route>

        <Route exact path="/orphn">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <div>
                  <Text>
                    <h4>Azure Orphan Resources</h4>
                    Orphan resources are quite a common theme in Azure environments.
                    The main purposes of deleting the orphaned resources are to save the cost of unused resources, prevent misconfiguration and simplify operations.
                    Orphan resource is an unused resource that can exists in Azure environment.In some cases we pay for the resources even though they are orphans and 
                    in other cases the resources have no cost but they can be the cause of misconfiguration and complexity operational.
                    <b>orphan Disk continues to charge a cost as long as we have not deleted. </b> 
                    <Link href="https://portal.azure.com/#blade/AppInsightsExtension/UsageNotebookBlade/ComponentId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2Fresourcegroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2Fmicrosoft.operationalinsights%2Fworkspaces%2Flanw-uva2-mt-azmtest001/ConfigurationId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2Fresourcegroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2F77bd8f25-77fc-4b27-aaa6-ef2af22dd0ec/Type/workbook/WorkbookTemplateName/ESG%20-%20Azure%20Orphan%20Resources" underline>
                      <b>Workbook</b>

                    </Link>

                  </Text>

                  <Text>
                    <h4>Azure Dedicated Clusters</h4>
                    Azure Monitor Logs Dedicated Clusters are a deployment option that enables advanced capabilities for Azure Monitor Logs. 
                    By being a part of the dedicated cluster, you will get <b>37% savings on your normal pay-as-you-go ingestion plan </b>. With MSCI specific enterprise discounts that are over and on top of this discount,
                    it translates to huge savings on your Log Analytics subscription cost.
                    Protect your data from <b>data center failures</b> with zones being separated physically by locations and equipped with independent power, cooling, and networking
                    If we are using more than one workspace for the production it might make sense to use a dedicated cluster. 
                    Cross-workspace queries will run faster if all workspaces are on the same cluster.
                    <Link href="https://portal.azure.com/#view/AppInsightsExtension/UsageNotebookBlade/ComponentId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2FresourceGroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2FMicrosoft.OperationalInsights%2Fworkspaces%2Flanw-uva2-mt-azmtest001/ConfigurationId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2Fresourcegroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2F81dce1c9-4145-4445-8a40-27fd1ba35152/Type/workbook/WorkbookTemplateName/ESG_Dedicated_Cluster" underline>
                      <b>Workbook</b>

                    </Link>

                  </Text>

                </div>
              </Col>
            </Row>
          </Container>
        </Route>

        <Route exact path="/Kubecost">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <iframe title="Azure ESG Cost Report" width="100%" height="100%" src="https://kubecost-eod.index-non-prod.msciapps.com/allocations.html" frameborder="0" allowFullScreen="true"></iframe></Col>
            </Row>
          </Container>
        </Route>

        <Route exact path="/Disk">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <iframe title="Azure ESG Cost Report" width="100%" height="100%" src="https://app.powerbi.com/reportEmbed?reportId=645e3d55-622c-46c9-bae4-cf9da0000baf&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
            </Row>
          </Container>
        </Route>

        <Route exact path="/Sandbox">
          <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row style={{ marginLeft: 0, marginRight: 0, backgroundColor: 'grey' }}>
              <Col style={{ paddingLeft: 0, paddingRight: 0, backgroundColor: 'grey' }}>
                <TopBar username={param.username} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              {/* <Col xs={currentTabWidth==208?2:1} style={{backgroundColor:'pink'}}> */}
              <Col style={{ paddingLeft: 0, paddingRight: 0, maxWidth: currentTabWidth }}>
                <SideBar />
              </Col>
              <Col >
                <iframe title="Azure ESG Cost Report" width="100%" height="100%" src="https://app.powerbi.com/reportEmbed?reportId=a04e7fad-adbf-4610-8150-7049a30afeb1&autoAuth=true&ctid=7a9376d4-7c43-480f-82ba-a090647f651d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe></Col>
            </Row>
          </Container>
        </Route>

      </Switch>
    </Router>
  );
  function handleClickOnLink() {
    window.alert('clicked on Link component which is rendered as html button');
  }
}