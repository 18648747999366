import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './components/authProvider';
import reportWebVitals from './reportWebVitals';



ReactDOM.render(
  
   <AzureAD provider={authProvider} forceLogin={true}>
  {(accountDetails) => {
           //console.log('>>>>>>>>>>>>...', abc);
          // console.log('hahahahhahah'+abc.accountInfo.account.name);       
      return  accountDetails.accountInfo!=null && <App username={accountDetails.accountInfo.account.name} email={accountDetails.accountInfo.account.userName}/>
      }}
 </AzureAD>, 
 document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
