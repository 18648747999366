import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css"
import configData from "../properties/config.json";
//import configData from process.env.REACT_APP_API_ENDPOINT
import axios from 'axios';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Blink from 'react-blink-text';



const customStylesApp = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: '1px dotted pink',
    color: state.selectProps.menuColor,
    background: "#dce3ec",
    padding: 20
  }),

  control: (_, { selectProps: { width } }) => ({
    width: width
  }),

  control: base => ({
    ...base,
    height: 35,
    minHeight: 40,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

const tableData1 = [
  { 'fruit': 'Apple', 'cost': 100 },
  { 'fruit': 'Orange', 'cost': 50 },
  { 'fruit': 'Banana', 'cost': 35 },
  { 'fruit': 'Mango', 'cost': 70 },
  { 'fruit': 'Pineapple', 'cost': 45 },
  { 'fruit': 'Papaya', 'cost': 40 },
  { 'fruit': 'Watermelon', 'cost': 35 }
];


const cofees_env=process.env.REACT_APP_ENVIRONMENT;

class MainContentViewRules extends Component {

 
 
  //requestApplicationNames = axios.get(configData.NON_PROD.FUNCTION_APP_URL + 'api/getEligibilityDataGenericQuery?query=select distinct(ApplicationName) from TbAppInformationStatic union select \'All\'');
  requestApplicationNames = axios.get(configData[cofees_env].FUNCTION_APP_URL + 'api/getEligibilityDataGenericQuery?query=select distinct(ApplicationName) from TbAppInformationStatic union select \'All\'');
  constructor(props) {
    super(props)

    this.state = {
      app: [],
      productCaptured: '',
      responseData: [],
      enableButtonClicked: '',
    }

    this.captureFieldProduct = this.captureFieldProduct.bind(this);
    this.enableRule = this.enableRule.bind(this);

  }



  captureFieldProduct(e) {

    this.setState({ productCaptured: e.label });
    //console.log(this.state.productCaptured);

  }

  AppNames() {

    //return (this.state.country.map(data => ({ label: data.Name, value: data.Id })))  
    return (this.state.app.map(data => ({ label: data, value: data })))

  }

  changeBackground(e) {
    e.target.style.background = '#5987da';
    e.target.style.color = 'white'
  }

  originalBackground(e) {
    e.target.style.background = '#dce3ec';
    e.target.style.color = 'black'
  }


  enableRule(e) {

    this.setState({
      enableButtonClicked: 'yes',
      //Reset values
      //enableRulesResponse: 'Please wait. Rule configuration in progress.......'
    })

    this.state.productCaptured !== '' && this.setState({
      enableRulesResponse: 'Please wait. Rule configuration in progress.......'
    })

    var query = ''
    this.state.productCaptured === 'All' ? query = 'select app.ApplicationName,app.Environment,client.ApplicationClient,ver.ApplicationVersion,widget.WidgetName,ruleLink.RulePriority, ruleLink.frequency,triggerType.TriggerType,ruleLink.isActive from TbAppInformationStatic app, TbWidgetInformationStatic widget, TbAppClientStatic client,TbAppVersionStatic ver,TbAppRuleLink ruleLink,TbTriggerTypeStatic triggerType where ruleLink.ApplicationCOFEESId=app.ApplicationCOFEESId and ruleLink.WidgetId=widget.WidgetId and ruleLink.AppVersionId=ver.AppVersionId and ruleLink.AppClientId=client.AppClientId and ruleLink.TriggerId=triggerType.TriggerId order by app.ApplicationName'
      : query = 'select app.ApplicationName,app.Environment,client.ApplicationClient,ver.ApplicationVersion,widget.WidgetName,ruleLink.RulePriority, ruleLink.frequency,triggerType.TriggerType,ruleLink.isActive from TbAppInformationStatic app, TbWidgetInformationStatic widget, TbAppClientStatic client,TbAppVersionStatic ver,TbAppRuleLink ruleLink,TbTriggerTypeStatic triggerType where ruleLink.ApplicationCOFEESId=app.ApplicationCOFEESId and ruleLink.WidgetId=widget.WidgetId and ruleLink.AppVersionId=ver.AppVersionId and ruleLink.AppClientId=client.AppClientId and ruleLink.TriggerId=triggerType.TriggerId and app.ApplicationName=' + '\'' + this.state.productCaptured + '\'' + ' order by app.ApplicationName'
    //console.log(query)
    this.state.productCaptured !== '' && axios.get(configData[cofees_env].FUNCTION_APP_URL + 'api/getEligibilityDataGenericQueryMap?query=' + query
    ).then(response => {
      this.setState({
        responseData: response.data,
        enableRulesResponse: 'success'

      })
    }).catch(errors => {
      // react on errors.
      console.log(errors)
      this.setState({
        enableRulesResponse: 'failed'
      })
    })

  }




  componentWillMount() {


    axios.all([this.requestApplicationNames]).then(axios.spread((...responses) => {

      this.setState({
        app: responses[0].data,
      })
      // use/access the results 
    })).catch(errors => {
      // react on errors.
      console.log(errors)
    })



  }

  getTrProps = (state, rowInfo, instance) => {
    if (rowInfo) {
      return {
        style: {
          border: rowInfo
            ? rowInfo.row.age >= 20
              ? "solid 1px black"
              : "none"
            : "none"
        }
      };
    }
    return {};
  };

  render() {



    const columns = [{
      Header: 'Application',
      accessor: 'Application',
      headerStyle: { color: 'black', background: 'white', border: '1px solid black' }   /** #A9A9A9 */
    }, {
      Header: 'Environment',
      accessor: 'Environment',
      headerStyle: { color: 'black', background: 'white', border: '1px solid black' }
    }, {
      Header: 'Client',
      accessor: 'Client',
      headerStyle: { color: 'black', background: 'white', border: '1px solid black' }
    }, {
      Header: 'Version',
      accessor: 'Version',
      headerStyle: { color: 'black', background: 'white', border: '1px solid black' }
    }, {
      Header: 'Widget',
      accessor: 'Widget',
      headerStyle: { color: 'black', background: 'white', border: '1px solid black' }
    }, {
      Header: 'Priority',
      accessor: 'Priority',
      headerStyle: { color: 'black', background: 'white', border: '1px solid black' }
    }, {
      Header: 'Frequency',
      accessor: 'Frequency',
      headerStyle: { color: 'black', background: 'white', border: '1px solid black' }
    }, {
      Header: 'Trigger Type',
      accessor: 'Trigger Type',
      headerStyle: { color: 'black', background: 'white', border: '1px solid black' },
      minWidth: 200,
    }]
    return (


      <Container fluid >
        <Row>&nbsp;</Row>
        <Row>&nbsp;</Row>
        <Row >
          <Col className='d-flex align-items-center justify-content-center'>
            <div style={{ width: '300px' }}>

              <Row>
                <Col xs={8}>
                  <h3 style={{ color: '#000000' }} > <b>Product</b> </h3>
                </Col>
                <Col>
                  {
                    (this.state.enableButtonClicked === 'yes' && this.state.productCaptured === '') &&
                    <h6 style={{ color: 'red' }}><i><b>*Required</b></i></h6>
                  }
                </Col>
              </Row>
              <Row>
                <Select styles={customStylesApp} options={this.AppNames()} width='300px' menuColor='black' onChange={this.captureFieldProduct}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#7692dd',
                      primary: '#5987da',
                    },
                  })}
                />
              </Row>
            </div>
          </Col>
          <Col >
            {/* partition row to 2 exact half so use h-50 class */}
            <Row className="h-50 d-inline-block">&nbsp;</Row>
            <Row >
              <Button variant="secondary" size="lg" active style={{ textAlign: 'center', backgroundColor: '#dce3ec', color: 'black', width: '160px', height: '45px', borderRadius: '6px', }}
                onMouseOver={this.changeBackground} onMouseOut={this.originalBackground} onClick={this.enableRule}>
                View
              </Button>
            </Row>
          </Col>

          <Col xs={7} style={{ maxWidth: '970px' }}>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            {

              this.state.enableButtonClicked === 'yes' && this.state.productCaptured !== '' && this.state.enableRulesResponse !== '' && this.state.enableRulesResponse === 'Please wait. Rule configuration in progress.......' &&

              <h3 style={{ color: '#000099' }}><Blink fontSize='20' color='blue' text='Retrieving configured rules......' ></Blink></h3>


            }
            {/* {
              this.state.enableButtonClicked === 'yes'  &&  this.state.productCaptured!=='' && this.state.enableRulesResponse === 'success' &&

              <h3 style={{color:'green'}} >Data retrieved successfully</h3>

            } */}
            {
              this.state.enableButtonClicked === 'yes' && this.state.productCaptured !== '' && this.state.enableRulesResponse === 'failed' &&

              <h3 style={{ color: 'red' }}>Failed to retrieve rule. Please check with the backend team.</h3>

            }

          </Col>

          <Row>&nbsp;</Row>
          <Row>&nbsp;</Row>
        </Row>
        <Row>&nbsp;
          {this.state.responseData != '' &&
            <ReactTable style={{ border: '1px solid black', textAlign: 'center' }}
              data={this.state.responseData}
              columns={columns}
              getTrProps={(state, rowInfo) => {
                //console.log(rowInfo);
                //console.log(rowInfo.row._index);
                return {
                  style: {
                    //background: rowInfo.row.status === "unresolved" ? 'green' : 'red'
                    background: rowInfo != null ? (rowInfo.row._index % 2 === 0 ? '#dce3ec' : '#B8B8B8') : '#dce3ec', // e3e9f8
                    border: '0.5px solid black'
                  }
                }
              }}
              getTdProps={(state, rowInfo) => {
                //console.log(rowInfo);
                //console.log(rowInfo.row._index);
                return {
                  style: {
                    //background: rowInfo.row.status === "unresolved" ? 'green' : 'red'
                    border: '0.5px solid black'
                  }
                }
              }}
              //className="-striped -highlight"
              //defaultPageSize = {20}  
              defaultPageSize={5}
              //pageSizeOptions = {[2,4, 6]
              pageSizeOptions={[5, 10]}
            />}
        </Row>
      </Container>
    );
  }

}



export default MainContentViewRules;