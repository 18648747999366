// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Configuration as MSALConfiguration } from 'msal';

// Msal Configurations
export const config: MSALConfiguration =
{
    auth: {
        authority: 'https://login.microsoftonline.com/7a9376d4-7c43-480f-82ba-a090647f651d',
        clientId: 'ad4587a5-cb81-49f4-adbe-b8aa6aff8467',
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        validateAuthority: true,
        // After being redirected to the "redirectUri" page, should user
        // be redirected back to the Url where their login originated from?
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};
// Authentication Parameters
export const authenticationParameters = {
    scopes: [`ad4587a5-cb81-49f4-adbe-b8aa6aff8467/.default`]
    //process.env.REACT_APP_AUTHENTICATION_PARAMETERS_SCOPES
}
export const authenticationParametersGraph = {
    scopes: [
        'openid'
    ]
}
// Options
export const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin
}
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)