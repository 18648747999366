import * as React from 'react';
import { Nav, INavLink, INavLinkGroup } from '@fluentui/react/lib/Nav';
import { SizeMe } from 'react-sizeme';
import { useState, useEffect, useRef } from 'react';
import { loadTheme } from 'office-ui-fabric-react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect, Link } from 'react-router-dom'
import { useBetween } from "use-between";


initializeIcons();

loadTheme({
  palette: {
    'neutralLighter': '#7692dd', // Color when you hiver on the side nav menus
    'neutralLight': '#7692dd'  // Color after you click on any of the side nav menus
  }
});

//const navStyles  : Partial<INavStyles> = {
const navStyles = (tabColor: any, tab_width: any) => ({

  root: {
    width: tab_width,
    //height: 705,
    //height: '93.4vh',
    //height: '100%'
    boxSizing: 'border-box',
    border: '3px solid #eee',
    overflowY: 'auto',
    backgroundColor: 'white',
    height: '93.4vh'
  },
  linkText: {
    color: 'black',
    fontWeight: '650',
  },
  compositeLink: {
    backgroundColor: 'white'
  },
});

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: '',
        url: '',
        key: "toggle_hide",
        icon: "GlobalNavButton",

      },
      // {
      //   name: 'Home',
      //   url: '',
      //   key: 'Home',
      //   expandAriaLabel: 'Expand Home section',
      //   collapseAriaLabel: 'Collapse Home section',
      //   icon: 'HomeSolid',
      //   isExpanded: true,
      // },

      {
        name: 'All Subscription Cost Analysis',
        url: '',
        icon: 'BarcHart4',
        key: 'AllSubscription'


      },
      {
        name: 'Prod Cost Analysis',
        url: '',
        key: '',
        expandAriaLabel: 'Expand Home section',
        collapseAriaLabel: 'Collapse Home section',
        links: [
          {
            name: 'Prod Subscription',
            url: '#',
            key: 'ProdSubscription'

          },
          {
            name: 'Orphan_Resources',
            url: 'https://portal.azure.com/#view/AppInsightsExtension/UsageNotebookBlade/ComponentId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2FresourceGroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2FMicrosoft.OperationalInsights%2Fworkspaces%2Flanw-uva2-mt-azmtest001/ConfigurationId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2Fresourcegroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2F2a18b06d-1c21-42a4-99c5-493b78ae888a/Type/workbook/WorkbookTemplateName/Azure%20Orphan%20Resources',
            key: '#',
            target: '_blank'


          },
          {
            name: 'Dedicated Cluster',
            url: 'https://portal.azure.com/#view/AppInsightsExtension/UsageNotebookBlade/ComponentId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2FresourceGroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2FMicrosoft.OperationalInsights%2Fworkspaces%2Flanw-uva2-mt-azmtest001/ConfigurationId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2Fresourcegroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2F81dce1c9-4145-4445-8a40-27fd1ba35152/Type/workbook/WorkbookTemplateName/ESG_Dedicated_Cluster',
            key: '#',
            target: '_blank'


          },
          {
            name: 'Classic App Migration',
            url: 'https://portal.azure.com/#view/AppInsightsExtension/UsageNotebookBlade/ComponentId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2FresourceGroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2FMicrosoft.OperationalInsights%2Fworkspaces%2Flanw-uva2-mt-azmtest001/ConfigurationId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2Fresourcegroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2Fa48f48b3-6dbf-4b98-983a-bfddf19fa042/Type/workbook/WorkbookTemplateName/Classic_App_Migration',
            key: '#',
            target: '_blank'


          },
          {
            name: 'Basic Logs Savings',
            url: 'https://portal.azure.com/#blade/AppInsightsExtension/UsageNotebookBlade/ComponentId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2FresourceGroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2FMicrosoft.OperationalInsights%2Fworkspaces%2Flanw-uva2-mt-azmtest001/ConfigurationId/%2Fsubscriptions%2Fcdbbc84c-7ff1-47d6-b9ec-f1ff99e4f258%2Fresourcegroups%2Frgrp-uva2-mt-azuremonitor%2Fproviders%2Fmicrosoft.insights%2Fworkbooks%2F7d649b24-1b6d-4c4d-8309-feb35299b0d0/Type/workbook/WorkbookTemplateName/Basic%20Logs%20Savings',
            key: '#',
            target: '_blank'


          },
          
          {
            name: 'Open Cost',
            url: '#',
            key: 'opencost'


          },
        ],
        isExpanded: false,

      },
     ],
  },
];


// Hook
function usePrevious(value: any) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export const useFormState = () => {
  const [currentTab, setTab] = useState('Subscription');
  const prevTab = '' + usePrevious(currentTab);

  const [currentTabColor, setTabColor] = useState('White');
  const [currentTabWidth, setTabWidth] = useState(208);

  return {
    currentTab, setTab, prevTab, currentTabColor, setTabColor, currentTabWidth, setTabWidth
  };
};



export const SideBar: React.FunctionComponent = () => {

  const useSharedFormState = () => useBetween(useFormState);
  const { currentTab, setTab } = useSharedFormState();
  const { prevTab } = useSharedFormState();
  const { currentTabColor, setTabColor } = useSharedFormState();
  const { currentTabWidth, setTabWidth } = useSharedFormState();


  return (
    <>


      {/* {currentTab === 'Home' && <Redirect to='/home' />} */}
      {currentTab === 'Configure Rules' && <Redirect to='configurerules' />}
      {currentTab === 'View Rules' && <Redirect to='viewrules' />}
      {currentTab === 'Reporting' && <Redirect to='reporting' />}
      {currentTab === 'All Subscription Cost Analysis' && <Redirect to='AllSubscription' />}
      {currentTab === 'Prod Subscription' && <Redirect to='ProdSubscription' />}
      {currentTab === 'Azure Workbookkk' && <Redirect to='orphn' />}
      {currentTab === 'Open Cost' && <Redirect to='opencost' />}
      

      {/* {console.log(currentTab)} */}


      <SizeMe monitorHeight>
        {({ size }) => (
          <Container fluid style={{ height: '100%', paddingLeft: 0, paddingRight: 0}}>
            <Row style={{ height: '100%', marginLeft: 0, marginRight: 0 } }>
              <Col style={{ height: '100%', paddingLeft: 0, paddingRight: 0, backgroundColor: 'white' }}>
                <Nav
                  //onLinkClick={(ev?: React.MouseEvent,item?:INavLink)=>console.log(item?.name)}
                  onLinkClick={(ev?: React.MouseEvent, item?: INavLink) => (item?.name === '' ? (currentTabWidth === 208 ? ([setTabWidth(50), setTab(currentTab)]) : ([setTabWidth(208), setTab(currentTab)])) : setTab('' + item?.name))}
                  selectedKey={currentTab}
                  ariaLabel="Nav basic example"
                  styles={navStyles(currentTabColor, currentTabWidth)}
                  groups={navLinkGroups}
                />
              </Col>
            </Row>
            {/* https://www.freakyjolly.com/react-how-to-get-height-and-width-of-component-container/ */}
          </Container>
        )}
      </SizeMe>
    </>
  );
};


function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
  alert(item?.name + ' tab clicked');
  return item?.name;

}

export const globalVariable = {
  INIT_FONT_SIZE: 16,
};

export var globalVariable2 = 'Hello'


export var xyz = (ev: any) => {
  return ev;
}
