import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
import Blink from 'react-blink-text';
import configData from "../properties/config.json";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const PriorityOption = [
  { label: "1 (Highest)", value: 355 },
  { label: "2", value: 54 },
  { label: "3", value: 43 },
  { label: "4", value: 61 },
  { label: "5 (Lowest)", value: 965 },
];



const TriggerTypes = [
  { label: "Day", value: 43 },
  { label: "Number of logins", value: 355 },

]

const customStylesMenus = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: '1px dotted pink',
    color: state.selectProps.menuColor,
    background: "#dce3ec",
    padding: 20
  }),
}

const cofees_env=process.env.REACT_APP_ENVIRONMENT;

class MainContentRulessConfigure extends Component {

  requestApplicationNames = axios.get(configData[cofees_env].FUNCTION_APP_URL + 'api/getEligibilityDataGenericQuery?query=select distinct(ApplicationName) from TbAppInformationStatic');
  requestPriorities = axios.get(configData[cofees_env].FUNCTION_APP_URL + 'api/getEligibilityDataGenericQuery?query=select WidgetId from TbWidgetInformationStatic');
  requestWidgetNames = axios.get(configData[cofees_env].FUNCTION_APP_URL + 'api/getEligibilityDataGenericQuery?query=select distinct(WidgetName) from TbWidgetInformationStatic');


  constructor(props) {
    super(props)

    this.state = {
      app: [],
      env: [],
      rules: [],
      version: [],
      clients: [],
      priorities: [],
      enableRulesResponse: [],
      triggerType: '',
      selectedClients: [],
      fieldsCaptured: [],
      productCaptured: '',
      envCaptured: '',
      verCaptured: '',
      clientCaptured: '',
      ruleTypeCaptured: '',
      priorityCaptured: '',
      freqCaptured: '',
      freqCapturedNoOfLogins: '',
      successOrFailureMessage: '',
      enableButtonClicked: '',
      emptyFieldPresent: false,
      enableRulesResponseStatus: '',
      finalEmptyFieldPresent: ''
    }
    this.handleChange = this.handleChange.bind(this);
    //this.captureFields = this.captureFields.bind(this);
    this.captureFieldProduct = this.captureFieldProduct.bind(this);
    this.captureFieldEnv = this.captureFieldEnv.bind(this);
    this.captureFieldVer = this.captureFieldVer.bind(this);
    this.captureFieldClient = this.captureFieldClient.bind(this);
    this.captureFieldRuleType = this.captureFieldRuleType.bind(this);
    this.captureFieldPriority = this.captureFieldPriority.bind(this);
    this.captureFieldFreq = this.captureFieldFreq.bind(this);
    this.captureFieldFreqNoOfLogins = this.captureFieldFreqNoOfLogins.bind(this);
    this.enableRule = this.enableRule.bind(this);

  }



  captureFieldProduct(e) {

    this.setState({ productCaptured: e.label });
    //console.log(this.state.productCaptured);
    axios.get(configData[cofees_env].FUNCTION_APP_URL + 'api/getEligibilityDataGenericQuery?query=select distinct(Environment) from TbAppInformationStatic where ApplicationName=' + '\'' + e.label + '\''
    ).then(response => {
      this.setState({
        env: response.data

      })
    }).catch(errors => {
      console.log(errors)
    })

  }

  captureFieldEnv(e) {
    this.setState({ envCaptured: e.label });
    //console.log('http://localhost:7071/api/getEligibilityDataGenericQuery?query=select ApplicationVersion from TbAppVersion ver,TbApplicationInformationStatic app \
    //where app.ApplicationName='+'\''+this.state.productCaptured+'\''+' and app.Environment='+'\''+e.label+'\''+' and app.ApplicationCOFEESId=ver.ApplicationCOFEESId');
    axios.get(configData[cofees_env].FUNCTION_APP_URL + 'api/getEligibilityDataGenericQuery?query=select ApplicationVersion from TbAppVersionStatic ver,TbAppInformationStatic app \
  where app.ApplicationName='+ '\'' + this.state.productCaptured + '\'' + ' and app.Environment=' + '\'' + e.label + '\'' + ' and app.ApplicationCOFEESId=ver.ApplicationCOFEESId'
    ).then(response => {
      this.setState({
        version: response.data

      })
    }).catch(errors => {
      console.log(errors)
    })

    axios.get(configData[cofees_env].FUNCTION_APP_URL + 'api/getEligibilityDataGenericQuery?query=select ApplicationClient from TbAppClientStatic cli,TbAppInformationStatic app \
  where app.ApplicationName='+ '\'' + this.state.productCaptured + '\'' + ' and app.Environment=' + '\'' + e.label + '\'' + ' and app.ApplicationCOFEESId=cli.ApplicationCOFEESId'
    ).then(response => {
      this.setState({
        clients: response.data

      })
    }).catch(errors => {
      console.log(errors)
    })

  }

  captureFieldVer(e) {
    this.setState({ verCaptured: e.label });
  }

  captureFieldClient(event) {
    //console.log(event);
    this.setState({ selectedClients: event });
    this.state.selectedClients.map((d) => d.label)
  }

  captureFieldRuleType(e) {
    this.setState({ ruleTypeCaptured: e.label });
  }

  captureFieldPriority(e) {
    this.setState({ priorityCaptured: e.label });
  }


  captureFieldFreq(e) {
    this.setState({ freqCaptured: e.target.value });
  }

  captureFieldFreqNoOfLogins(e) {
    this.setState({ freqCapturedNoOfLogins: e.target.value });
  }


  handleChange(event) {
    //console.log(event.label);
    this.setState({ triggerType: event.label });
  }


  changeBackground(e) {
    e.target.style.background = '#5987da';
    e.target.style.color = 'white'
  }

  originalBackground(e) {
    e.target.style.background = '#dce3ec';
    e.target.style.color = 'black'
  }


  enableRule(e) {

    this.setState({
      enableButtonClicked: 'yes',
      //Reset values
      enableRulesResponse: 'Please wait. Rule configuration in progress.......'
    })

    //reset values
    this.state.triggerType === 'Day' && (this.state.freqCapturedNoOfLogins = '');
    this.state.triggerType === 'Number of logins' && (this.state.freqCaptured = '');
    this.state.productCaptured === '' && (this.state.emptyFieldPresent = true);
    this.state.envCaptured === '' && (this.state.emptyFieldPresent = true);
    this.state.verCaptured === '' && (this.state.emptyFieldPresent = true);
    this.state.selectedClients.length === 0 && (this.state.emptyFieldPresent = true);
    this.state.ruleTypeCaptured === '' && (this.state.emptyFieldPresent = true);
    this.state.priorityCaptured === '' && (this.state.emptyFieldPresent = true);
    this.state.triggerType === '' && (this.state.emptyFieldPresent = true);
    //(this.state.freqCaptured=='' && this.state.freqCapturedNoOfLogins=='') && (this.state.emptyFieldPresent=true);
    (this.state.triggerType === 'Day' && this.state.freqCaptured === '') && (this.state.emptyFieldPresent = true);
    (this.state.triggerType === 'Number of logins' && this.state.freqCapturedNoOfLogins === '') && (this.state.emptyFieldPresent = true);


    const enableRuleBody = {
      widgetName: this.state.ruleTypeCaptured, appName: this.state.productCaptured, appEnv: this.state.envCaptured,
      rulePriority: this.state.priorityCaptured,
      appClient: this.state.selectedClients.map((d) => d.label), triggerType: this.state.triggerType,
      frequency: this.state.triggerType === 'Day' ? this.state.freqCaptured : this.state.freqCapturedNoOfLogins,
      insertUser: this.props.email, isActive: 1, appVersion: this.state.verCaptured
    };
    console.log(enableRuleBody);
    //console.log(this.props.email);

    this.state.emptyFieldPresent === false ? this.state.finalEmptyFieldPresent = 'no' : this.state.finalEmptyFieldPresent = '';

    (this.state.emptyFieldPresent === false &&
      axios.post(configData[cofees_env].FUNCTION_APP_URL + 'api/postEligibilityRules',
        enableRuleBody).then(response => {
          //console.log(response.data[0]);  
          this.setState({
            enableRulesResponse: response.data
          })

        }).catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            this.setState({
              enableRulesResponseStatus: error.response.status,
              enableRulesResponse: error.response.data
            })

          } else if (error.request) {
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        })
    )

    //reset at the end
    this.state.emptyFieldPresent = false;
    //console.log("Testing and condition " + (this.state.enableButtonClicked === 'yes' && this.state.envCaptured === ''))
  }

  componentWillMount() {


    axios.all([this.requestApplicationNames, this.requestPriorities, this.requestWidgetNames]).then(axios.spread((...responses) => {

      this.setState({
        app: responses[0].data,
        priorities: responses[1].data,
        rules: responses[2].data

      })

    })).catch(errors => {
      console.log(errors)
    })
  }
  AppNames() {
    return (this.state.app.map(data => ({ label: data, value: data })))
  }

  EnvNames() {
    return (this.state.env.map(data => ({ label: data, value: data })))
  }

  EnvVersions() {
    return (this.state.version.map(data => ({ label: data, value: data })))
  }


  EnvClients() {
    return (this.state.clients.map(data => ({ label: data, value: data })))
  }


  RuleNames() {
    return (this.state.rules.map(data => ({ label: data, value: data })))
  }

  Priorities() {
    return (this.state.priorities.map(data => ({ label: data, value: data })))
  }

  render() {
    return (
      // <Container fluid style={{ backgroundColor: 'orange' }}>
      <Container fluid >
        <Row>&nbsp;</Row>
        <Row>&nbsp;</Row>
        <Row >
          <Col className='d-flex align-items-center justify-content-center'>
            <div style={{ width: '300px' }}>

              <Row>
                <Col xs={8}>
                  <h3 style={{ color: '#000000' }} > <b>Product</b> </h3>
                </Col>
                {/* <Col style={{ backgroundColor: 'pink' }}> */}
                <Col>
                  {(this.state.enableButtonClicked === 'yes' && this.state.productCaptured === '') &&
                    <h6 style={{ color: 'red' }}><i><b>*Required</b></i></h6>
                  }
                </Col>
              </Row>
              <Row>
                <Select options={this.AppNames()} styles={customStylesMenus} width='300px' menuColor='black' onChange={this.captureFieldProduct}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#7692dd',
                      primary: '#5987da',
                    },
                  })}
                />


              </Row>
            </div>
          </Col>
          <Col className='d-flex align-items-center justify-content-center'>
            <div style={{ width: '300px' }}>
              <Row>
                <Col xs={8}>
                  <h3 style={{ color: '#000000' }} > <b>Environment </b></h3>
                </Col>
                <Col>
                  {
                    (this.state.enableButtonClicked === 'yes' && this.state.envCaptured === '') &&
                    <h6 style={{ color: 'red' }}><i><b>*Required</b></i></h6>
                  }
                </Col>

              </Row>
              <Row>
                <Select options={this.EnvNames()} styles={customStylesMenus} width='300px' menuColor='black' onChange={this.captureFieldEnv}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#7692dd',
                      primary: '#5987da',
                    },
                  })}
                />
              </Row>
            </div>
          </Col>

          <Col className='d-flex align-items-center justify-content-center'>
            <div style={{ width: '300px' }}>
              <Row>
                <Col xs={8}> <h3 style={{ color: '#000000' }} > <b>Version </b></h3></Col>
                <Col>
                  {(this.state.enableButtonClicked === 'yes' && this.state.verCaptured === '') &&
                    <h6 style={{ color: 'red' }}><i><b>*Required</b></i></h6>
                  }
                </Col>
              </Row>

              <Row>
                <Select options={this.EnvVersions()} styles={customStylesMenus} width='300px' menuColor='black' onChange={this.captureFieldVer}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#7692dd',
                      primary: '#5987da',
                    },
                  })}
                />
              </Row>
            </div>
          </Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col className='d-flex align-items-center justify-content-center'>
            <div style={{ width: '300px' }}>
              <Row>
                <Col xs={8}> <h3 style={{ color: '#000000' }} > <b>Client</b> </h3></Col>
                <Col>
                  {(this.state.enableButtonClicked === 'yes' && this.state.selectedClients.length === 0) &&
                    <h6 style={{ color: 'red' }}><i><b>*Required</b></i></h6>
                  }
                </Col>
              </Row>

              <Row>
                <Select isMulti options={this.EnvClients()} styles={customStylesMenus} width='300px' menuColor='black' onChange={this.captureFieldClient}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#7692dd',
                      primary: '#5987da',
                    },
                  })}
                />
              </Row>
            </div>
          </Col>


          <Col className='d-flex align-items-center justify-content-center'>
            <div style={{ width: '300px' }}>
              <Row>
                <Col xs={8}><h3 style={{ color: '#000000', width: '200px' }} ><b> Rule Type </b></h3></Col>
                <Col>
                  {(this.state.enableButtonClicked === 'yes' && this.state.ruleTypeCaptured === '') &&
                    <h6 style={{ color: 'red' }}><i><b>*Required</b></i></h6>
                  }
                </Col>
              </Row>

              <Row>
                <Select options={this.RuleNames()} styles={customStylesMenus} width='300px' menuColor='black' onChange={this.captureFieldRuleType}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#7692dd',
                      primary: '#5987da',
                    },
                  })}
                />
              </Row>
            </div>
          </Col>

          <Col className='d-flex align-items-center justify-content-center'>
            <div style={{ width: '300px' }}>
              <Row>
                <Col xs={8}><h3 style={{ color: '#000000' }} > <b>Priority</b> </h3></Col>
                <Col>
                  {(this.state.enableButtonClicked === 'yes' && this.state.priorityCaptured === '') &&
                    <h6 style={{ color: 'red', }}><i><b>*Required</b></i></h6>
                  }
                </Col>
              </Row>


              <Row>
                <Select options={this.Priorities()} styles={customStylesMenus} width='300px' menuColor='black' onChange={this.captureFieldPriority}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      /*primary25: 'hotpink',
                      primary: 'purple',*/
                      primary25: '#7692dd',
                      primary: '#5987da',
                    },
                  })}
                />
              </Row>
            </div>
          </Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col className='d-flex align-items-center justify-content-center'>
            <div style={{ width: '300px' }}>
              <Row>
                <Col xs={8}> <h3 style={{ color: '#000000' }} > <b>Trigger Type </b></h3></Col>
                <Col>
                  {(this.state.enableButtonClicked === 'yes' && this.state.triggerType === '') &&
                    <h6 style={{ color: 'red' }}><i><b>*Required</b></i></h6>
                  }
                </Col>
              </Row>
              <Row>
                <Select options={TriggerTypes} styles={customStylesMenus} width='300px' menuColor='black' onChange={this.handleChange}

                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#7692dd',
                      primary: '#5987da',
                    },
                  })}
                />
              </Row>
            </div>
          </Col>

          <Col className='d-flex align-items-center justify-content-center' >
            {this.state.triggerType === 'Day' &&
              <div style={{ width: '300px' }}>
                <Row>
                  <Col xs={8}><h3 style={{ color: '#000000' }} > <b>Frequency </b></h3></Col>
                  <Col>
                    {(this.state.enableButtonClicked === 'yes' && this.state.triggerType === 'Day' && this.state.freqCaptured === '') &&
                      <h6 style={{ color: 'red' }}><i><b>*Required</b></i></h6>
                    }
                  </Col>

                </Row>


                <input type="number" min="0" name="freqNoOfDays" id="freqNoOfDays" placeholder="Number of days" onChange={this.captureFieldFreq}
                  style={{ width: '150px', height: '35px', fontSize: '18px', fontFamily: 'Times New Roman' }}
                />

              </div>
            }

            {this.state.triggerType === 'Number of logins' &&
              <div style={{ width: '300px' }}>
                <Row>
                  <Col xs={8}> <h3 style={{ color: '#000000', }} ><b> Frequency </b></h3></Col>
                  <Col>
                    {(this.state.enableButtonClicked === 'yes' && this.state.triggerType === 'Number of logins' && this.state.freqCapturedNoOfLogins === '') &&
                      <h6 style={{ color: 'red' }}><i><b>*Required</b></i></h6>
                    }
                  </Col>
                </Row>

                <input type="number" min="0" name="noOfLogins" id="noOfLogins" placeholder="No. of logins" onChange={this.captureFieldFreqNoOfLogins}
                  style={{ width: '150px', height: '35px', fontSize: '18px', fontFamily: 'Times New Roman' }}
                />
              </div>
            }
          </Col>
          <Col >&nbsp;</Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col className='d-flex align-items-center justify-content-center'>
            <Button variant="secondary" size="lg" active style={{ textAlign: 'center', backgroundColor: '#dce3ec', color: 'black', width: '160px', height: '42px', borderRadius: '6px', }}
              onMouseOver={this.changeBackground} onMouseOut={this.originalBackground} onClick={this.enableRule}>
              Enable Rule
            </Button>
          </Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col>

            {
              this.state.enableButtonClicked === 'yes' && this.state.enableRulesResponse === 'Request processed successfully ' &&
              <h3 style={{ color: 'green', textAlign: 'center' }} >Rule created successfully</h3>
            }
            {
              this.state.enableButtonClicked === 'yes' && this.state.enableRulesResponse !== '' && this.state.enableRulesResponse !== 'Please wait. Rule configuration in progress.......' && this.state.enableRulesResponse !== 'Request processed successfully ' &&
              <h3 style={{ color: 'red', textAlign: 'center' }}>Failed to create rule. Please check with the backend team.</h3>
            }
            {
              this.state.enableButtonClicked === 'yes' && this.state.finalEmptyFieldPresent === 'no' && this.state.enableRulesResponse !== '' && this.state.enableRulesResponse === 'Please wait. Rule configuration in progress.......' &&
              <h3 style={{ color: '#000099', textAlign: 'center' }}><Blink color='blue' text='Please wait. Rule configuration in progress......' fontSize='30'></Blink></h3>
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MainContentRulessConfigure
